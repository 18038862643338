.big {
    font-family: "Lato", sans-serif;
    z-index: 1;
    font-size: 80px;
    color: rgba(0, 0, 0, 0.06);
    font-weight: 900;
    letter-spacing: 5px;
    text-align: center;
  }
  .tr-2 {
    z-index: 2;
    text-align: center;
    margin-top: -65px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
  }
  .my-image {
    width: 400px;
    height: 470px;
    background-size: cover;
    object-fit: cover;
    padding-top: 5%;
  }
  #about-section {
    display: flex;
    margin: 0;
    z-index: 1;
  }
  #about-container {
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .group-button {
    display: flex;
  }
  .workPlace {
    font-weight: 400;
    color: #4d4d4e;
  }
  .first-div {
    margin-bottom: 4%;
  }
  .first-paragraph {
    margin-bottom: 1%;
    color: #545353;
    font-weight: 500;
  }
  .about-description {
    line-height: 30px;
    font-weight: 600;
    color: #5f5f5f;
    margin-top: 7%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
  @media only screen and (max-width: 430px) {
    .bg {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 55px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 990px) and (min-width: 768px) {
    .my-image {
      width: 300px;
      height: 573px;
      background-size: cover;
      object-fit: cover;
      padding-top: 60%;
    }
  }
  @media screen and (max-width: 340px) and (min-width: 320px) {
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -55px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }

    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 17px;
    }
    .my-image{
      width: 230px;
      height: 370px;
      background-size: cover;
      object-fit: cover;
      padding-top: 30%;
  }
  .about{
    margin: 15% auto;
  }
  }
  
  
  @media screen and (max-width: 415px) and (min-width: 341px) {
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -40px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 17px;
    }
    .my-image{
      width: 230px;
      height: 400px;
      background-size: cover;
      object-fit: cover;
      padding-top: 30%
    }
    .about{
      margin: 8% auto;
    }
  
  }
  @media screen and (max-width: 570px) and (min-width: 416px) {
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -40px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 17px;
    }
    .my-image{
    width: 285px;
    height: 465px;
    background-size: cover;
    object-fit: cover;
    padding-top: 20%;
  }
    .about{
      margin: 8% auto;
    }
  
  }
  
  @media screen and (max-width: 900px) and (min-width: 571px) {
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 60px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -55px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 17px;
    }
    .my-image{
      width: 335px;
    height: 470px;
    background-size: cover;
    object-fit: cover;
    padding-top: 20%
    }
    .about{
      margin: 8% auto;
    }
   
  }
  @media screen and (max-width: 991px) and (min-width: 901px){
    .about{
      margin: 8% auto;
    }
    .my-image{
      width: 330px;
    height: 560px;
    background-size: cover;
    object-fit: cover;
    padding-top: 20%
    }
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 60px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 17px;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -52px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    
  }
  @media screen and (max-width: 1200px) and (min-width: 992px){
    .about{
      margin: 16% auto;
    }
    .my-image{
      width: 330px;
    height: 560px;
    background-size: cover;
    object-fit: cover;
    padding-top: 20%
    }
    .big {
      font-family: "Lato", sans-serif;
      z-index: 1;
      font-size: 60px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 5px;
      text-align: center;
    }
    .about-description {
      line-height: 30px;
      font-weight: 600;
      color: #5f5f5f;
      margin-top: 7%;
      margin-bottom: 4%;
      margin-left: auto;
      margin-right: auto;
      font-size: 15px;
    }
    .tr-2 {
      z-index: 2;
      text-align: center;
      margin-top: -52px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    
  }
  