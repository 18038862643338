.idra{
color: #f26f26;
}
.poll{
color: #5c7d95;
}
.stay{
color: white;
font-weight: 600;
}
.fit{
color: #d67c9bf3;
font-weight: 600;
}

.effect{
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.effect .effect-img{
    font-size: 0;
    overflow: hidden;
}
.effect .effect-img img{
    width: 100%;
    height: auto;
    transition: all .3s;
}
.effect .effect-img video{
    width: 100%;
    height: auto;
    transition: all .3s;
}
.effect:hover .effect-img img{
    transform: scale(1.1);
}

.effect .effect-text .inner{
    position: absolute;
    padding: 15px;
    z-index: 1;
}
.effect .effect-text:before,
.effect .effect-text:after{
    position: absolute;
    display: block;
    width: 100%;
    height: 0;
    content: '';
    
}

.effect:hover .effect-text{
    opacity: 1;
}
.effect:hover .effect-text:after,
.effect:hover .effect-text:before{
    animation-delay: .1s;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    
}
.effect:hover .effect-text:after{
    animation-name: left-up;
}
.effect:hover .effect-text:before{
    animation-name: right-dn;
}
@-webkit-keyframes left-up {
    0% {
        left: 100%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 100%;
        left: 0;
    }
}
@-webkit-keyframes right-dn {
    0% {
        left: -100%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 100%;
        left: 0;
    }
}


@media screen and (max-width: 415px) and (min-width: 320px) {
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 20%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 80px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 23px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -26px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 13px;
        line-height: 30px;
        margin-top: 10%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-text{
        position: absolute;
        top: 20px;
        right: 20px;
        bottom: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        overflow: hidden;
        transition: all .3s ease-in;
        opacity: 0;
        border: 1px solid #ffffff;
      
    }
    .effect .effect-text p{
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 20px;
    }
    .effect .effect-text h2{
        height: 45px;
        color: #ffffff;
        font-size: 25px;
        margin: 0;
    }
    .effect .effect-btn .btn{
       
            display: inline-block;
            height: 35px;
            padding: 4px 15px;
            color: rgb(39, 133, 156);
            background: #ffffff;
            font-weight: 600;
            width: 51%;
            font-size: 14px
    }
    .view-button{
        display: flex;
        margin: auto;
        position: relative;
        width: 52%;
        margin-bottom: 6%;
        background: rgb(39, 133, 156);
        border-radius: 25px;
        
    }
    .view-icon{
       
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1.7rem;
        color: white;
        padding-left: 5%;
    }
    a.view-butt{
        text-decoration: none;
    }
    p.view-paragraph{
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 0px 0px 12px;
        transition: all 0.3s ease-in-out 0s;
        color: white;
        z-index: 1;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 10px;
        font-size: 14px;
    
    }
  }
  @media screen and (max-width: 520px) and (min-width: 416px) {
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 18%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 45px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -40px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 17px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .effect .effect-img img{
        width: 85%;
        height: auto;
        transition: all .3s;
    }
    .effect .effect-img video{
        width: 85%;
        height: auto;
        transition: all .3s;
    }
    @-webkit-keyframes left-up {
        0% {
            left: 65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }
    @-webkit-keyframes right-dn {
        0% {
            left: -65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }
    .effect:hover .effect-text:after,
    .effect:hover .effect-text:before{
        animation-delay: .1s;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        
    }
    .effect:hover .effect-text:after{
        animation-name: left-up;
    }
    .effect:hover .effect-text:before{
        animation-name: right-dn;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 13px;
        line-height: 30px;
        margin-top: 10%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-text{
        position: absolute;
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        overflow: hidden;
        transition: all .3s ease-in;
        opacity: 0;
        border: 1px solid #ffffff;
      
    }
    .effect .effect-text p{
        color: #ffffff;
        font-size: 15px;
        margin-bottom: 20px;
    }
    .effect .effect-text h2{
        height: 45px;
        color: #ffffff;
        font-size: 25px;
        margin: 0;
    }
    .effect .effect-btn .btn{
       
            display: inline-block;
            height: 35px;
            padding: 4px 15px;
            color: rgb(39, 133, 156);
            background: #ffffff;
            font-weight: 600;
            width: 45%;
            font-size: 16px
    }
    .view-button{
        display: flex;
        margin: auto;
        position: relative;
        width: 40%;
        margin-bottom: 6%;
        background: rgb(39, 133, 156);
        border-radius: 25px;
        
    }
    .view-icon{
       
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1.7rem;
        color: white;
        padding-left: 5%;
    }
    a.view-butt{
        text-decoration: none;
    }
    p.view-paragraph{
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 0px 0px 12px;
        transition: all 0.3s ease-in-out 0s;
        color: white;
        z-index: 1;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 10px;
        font-size: 14px;
    
    }
  }
  @media screen and (max-width: 650px) and (min-width: 521px) {
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 18%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 45px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -40px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 17px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 18px;
        line-height: 30px;
        margin-top: 10%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-img img{
        width: 80%;
        height: auto;
        transition: all .3s;
    }
    .effect .effect-img video{
        width: 80%;
        height: auto;
        transition: all .3s;
    }
    @-webkit-keyframes left-up {
        0% {
            left: 65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }
    @-webkit-keyframes right-dn {
        0% {
            left: -65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }
 
    .effect:hover .effect-text:after,
    .effect:hover .effect-text:before{
        animation-delay: .1s;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        
    }
    .effect:hover .effect-text:after{
        animation-name: left-up;
    }
    .effect:hover .effect-text:before{
        animation-name: right-dn;
    }
        .effect .effect-text{
        position: absolute;
        top: 30px;
        right: 45px;
        bottom: 30px;
        left: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        overflow: hidden;
        transition: all .3s ease-in;
        opacity: 0;
        border: 1px solid #ffffff;
      
    }
    .effect .effect-text p{
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .effect .effect-text h2{
        height: 45px;
        color: #ffffff;
        font-size: 25px;
        margin: 0;
    }
    .effect .effect-btn .btn{
       
            display: inline-block;
            height: 35px;
            padding: 4px 15px;
            color: rgb(39, 133, 156);
            background: #ffffff;
            font-weight: 600;
            width: 35%;
            font-size: 16px
    }
    .view-button{
        display: flex;
        margin: auto;
        position: relative;
        width: 33%;
        margin-bottom: 6%;
        background: rgb(39, 133, 156);
        border-radius: 25px;
        
    }
    .view-icon{
       
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1.7rem;
        color: white;
        padding-left: 5%;
    }
    a.view-butt{
        text-decoration: none;
    }
    p.view-paragraph{
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 0px 0px 12px;
        transition: all 0.3s ease-in-out 0s;
        color: white;
        z-index: 1;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 10px;
        font-size: 14px;
    
    }
    
}
  @media screen and (max-width: 768px) and (min-width: 651px) {
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 13%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 55px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -45px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 18px;
        line-height: 30px;
        margin-top: 5%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-img img{
        width: 80%;
        height: auto;
        transition: all .3s;
    }
    .effect .effect-img video{
        width: 80%;
        height: auto;
        transition: all .3s;
    }
    @-webkit-keyframes left-up {
        0% {
            left: 65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }
    @-webkit-keyframes right-dn {
        0% {
            left: -65%;
            height: 0;
        }
        50% {
            left: 0;
            height: 0;
        }
        100% {
            height: 65%;
            left: 0;
        }
    }

    .effect:hover .effect-text:after,
    .effect:hover .effect-text:before{
        animation-delay: .1s;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        
    }
    .effect:hover .effect-text:after{
        animation-name: left-up;
    }
    .effect:hover .effect-text:before{
        animation-name: right-dn;
    }
    .effect .effect-text{
        position: absolute;
        top: 40px;
        right: 50px;
        bottom: 40px;
        left: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        overflow: hidden;
        transition: all .3s ease-in;
        opacity: 0;
        border: 1px solid #ffffff;
      
    }
    .effect .effect-text p{
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .effect .effect-text h2{
        height: 45px;
        color: #ffffff;
        font-size: 25px;
        margin: 0;
    }
    .effect .effect-btn .btn{
       
            display: inline-block;
            height: 35px;
            padding: 4px 15px;
            color: rgb(39, 133, 156);
            background: #ffffff;
            font-weight: 600;
            width: 32%;
            font-size: 16px;
            margin-top: 5%;
    }
    .view-button{
        display: flex;
        margin: auto;
        position: relative;
        width: 26%;
        margin-bottom: 6%;
        background: rgb(39, 133, 156);
        border-radius: 25px;
        
    }
    .view-icon{
       
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1.7rem;
        color: white;
        padding-left: 5%;
    }
    a.view-butt{
        text-decoration: none;
    }
    p.view-paragraph{
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 0px 0px 12px;
        transition: all 0.3s ease-in-out 0s;
        color: white;
        z-index: 1;
        text-align: center;
        letter-spacing: 3px;
        margin-bottom: 10px;
        font-size: 14px;
    
    }
    
   
  }
  @media screen and (max-width: 900px) and (min-width: 769px) {
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 13%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 55px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -45px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 18px;
        line-height: 30px;
        margin-top: 5%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-img img {
        width: 65%;
        height: auto;
        transition: all .3s;
  }
  .effect .effect-img video {
    width: 65%;
    height: auto;
    transition: all .3s;
}
  @-webkit-keyframes left-up {
    0% {
        left: 65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
}
@-webkit-keyframes right-dn {
    0% {
        left: -65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
}

.effect:hover .effect-text:after,
.effect:hover .effect-text:before{
    animation-delay: .1s;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    
}
.effect:hover .effect-text:after{
    animation-name: left-up;
}
.effect:hover .effect-text:before{
    animation-name: right-dn;
}
.effect .effect-text {
    position: absolute;
    top: 45px;
    right: 155px;
    bottom: 45px;
    left: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    transition: all .3s ease-in;
    opacity: 0;
    border: 1px solid #ffffff;
  }
  .effect .effect-text p{
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 20px;
}
.effect .effect-text h2{
    height: 45px;
    color: #ffffff;
    font-size: 25px;
    margin: 0;
}
.effect .effect-btn .btn{
   
        display: inline-block;
        height: 35px;
        padding: 4px 15px;
        color: rgb(39, 133, 156);
        background: #ffffff;
        font-weight: 600;
        width: 32%;
        font-size: 16px;
        margin-top: 5%;
}
.view-button{
    display: flex;
    margin: auto;
    position: relative;
    width: 22%;
    margin-bottom: 6%;
    background: rgb(39, 133, 156);
    border-radius: 25px;
    
}
.view-icon{
   
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1.7rem;
    color: white;
    padding-left: 5%;
}
a.view-butt{
    text-decoration: none;
}
p.view-paragraph{
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 0px 0px 12px;
    transition: all 0.3s ease-in-out 0s;
    color: white;
    z-index: 1;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 10px;
    font-size: 14px;

}

}
  @media screen and (max-width: 991px) and (min-width: 901px) {
        .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 13%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 55px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -45px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 10%;
        font-weight: 600;
        color: #717173;
        font-size: 18px;
        line-height: 30px;
        margin-top: 5%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-img img {
        width: 75%;
        height: auto;
        transition: all .3s;
  }
  .effect .effect-img video {
    width: 75%;
    height: auto;
    transition: all .3s;
}
  @-webkit-keyframes left-up {
    0% {
        left: 65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
}
@-webkit-keyframes right-dn {
    0% {
        left: -65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
}

.effect:hover .effect-text:after,
.effect:hover .effect-text:before{
    animation-delay: .1s;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    
}
.effect:hover .effect-text:after{
    animation-name: left-up;
}
.effect:hover .effect-text:before{
    animation-name: right-dn;
}
.effect .effect-text {
    position: absolute;
    top: 35px;
    right: 125px;
    bottom: 35px;
    left: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    transition: all .3s ease-in;
    opacity: 0;
    border: 1px solid #ffffff;
  }
  .effect .effect-text p{
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 20px;
}
.effect .effect-text h2{
    height: 45px;
    color: #ffffff;
    font-size: 25px;
    margin: 0;
}
.effect .effect-btn .btn{
   
        display: inline-block;
        height: 35px;
        padding: 4px 15px;
        color: rgb(39, 133, 156);
        background: #ffffff;
        font-weight: 600;
        width: 32%;
        font-size: 17px;
        margin-top: 5%;
}
.view-button{
    display: flex;
    margin: auto;
    position: relative;
    width: 18%;
    margin-bottom: 6%;
    background: rgb(39, 133, 156);
    border-radius: 25px;
    
}
.view-icon{
   
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1.7rem;
    color: white;
    padding-left: 5%;
}
a.view-butt{
    text-decoration: none;
}
p.view-paragraph{
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 0px 0px 12px;
    transition: all 0.3s ease-in-out 0s;
    color: white;
    z-index: 1;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 10px;
    font-size: 14px;

}

}
@media screen and (max-width: 1301px) and (min-width: 992px) {
    .portfolio-col{
    text-align: center;
}
#portfolio.container-fluid{
    margin-top: 13%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6%;
}
.portfolio-title {
    font-family: 'Lato', sans-serif;
    z-index: 1;
    font-size: 60px;
    color: rgba(0, 0, 0, 0.06);
    font-weight: 900;
    letter-spacing: 8px;
    text-align: center;
    font-size: 55px;
    
}
.portfolio-small {
    z-index: 2;
    text-align: center;
    margin-top: -45px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}
.portfolio-des{
    text-align: center;
    margin-bottom: 3%;
    font-weight: 600;
    color: #717173;
    font-size: 18px;
    line-height: 30px;
    margin-top: 5%;
    font-family: 'Poppins';
    letter-spacing: 2px;
}
.effect .effect-img img {
    width: 100%;
    height: 330px;
    transition: all .3s;
    object-fit: cover;
}
.effect .effect-img video{
    width: 100%;
    height: 330px;
    transition: all .3s;
    object-fit: cover;
}
@-webkit-keyframes left-up {
0% {
    left: 65%;
    height: 0;
}
50% {
    left: 0;
    height: 0;
}
100% {
    height: 65%;
    left: 0;
}
}
@-webkit-keyframes right-dn {
0% {
    left: -65%;
    height: 0;
}
50% {
    left: 0;
    height: 0;
}
100% {
    height: 65%;
    left: 0;
}
}

.effect:hover .effect-text:after,
.effect:hover .effect-text:before{
animation-delay: .1s;
animation-duration: .5s;
animation-iteration-count: 1;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;

}
.effect:hover .effect-text:after{
animation-name: left-up;
}
.effect:hover .effect-text:before{
animation-name: right-dn;
}
.effect .effect-text {
position: absolute;
top: 25px;
right: 25px;
bottom: 25px;
left: 25px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
background: rgba(0, 0, 0, .5);
overflow: hidden;
transition: all .3s ease-in;
opacity: 0;
border: 1px solid #ffffff;
}
.effect .effect-text p{
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 20px;
}
.effect .effect-text h2{
    height: 45px;
    color: #ffffff;
    font-size: 25px;
    margin: 0;
}
.effect .effect-btn .btn{
   
        display: inline-block;
        height: 35px;
        padding: 4px 15px;
        color: rgb(39, 133, 156);
        background: #ffffff;
        font-weight: 600;
        width: 50%;
        font-size: 15px;
       
}
.view-button{
    display: flex;
   margin: auto;
    position: relative;
    width: max-content;
    
    margin-bottom: 4%;
    
}
.view-icon{
    width: 40px;
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    background: rgb(39, 133, 156);
    z-index: 1;
    font-size: 1.7rem;
    color: white;
}
a.view-butt{
    text-decoration: none;
}
p.view-paragraph{
font-weight: 600;
text-transform: uppercase;
padding: 8px 20px 0px 25px;
transition: all 0.3s ease-in-out 0s;
color: rgb(0, 0, 0);
z-index: 1;
text-align: center;
letter-spacing: 3px;

}
.view-button::before{
content: "";
position: absolute;
width: 40px;
height: 40px;
left: 0px;
top: 0px;
background: rgb(39, 133, 156);
transition: all 0.3s ease-in-out 0s;
border-radius: 25px;
}
.view-button:hover::before{
    width: 130%;
    cursor: pointer;
    z-index: -1;
}
.view-button:hover  p.view-paragraph{
     color: white;
     padding: 6px 20px 0px 25px;
     cursor: pointer;
     text-decoration: none;
     letter-spacing: 3px;
}

}
@media screen and (max-width: 1500px) and (min-width: 1301px) {
    .portfolio-col{
    text-align: center;
}
#portfolio.container-fluid{
    margin-top: 13%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6%;
}
.portfolio-title {
    font-family: 'Lato', sans-serif;
    z-index: 1;
    font-size: 60px;
    color: rgba(0, 0, 0, 0.06);
    font-weight: 900;
    letter-spacing: 8px;
    text-align: center;
    font-size: 55px;
    
}
.portfolio-small {
    z-index: 2;
    text-align: center;
    margin-top: -45px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}
.portfolio-des{
    text-align: center;
    margin-bottom: 3%;
    font-weight: 600;
    color: #717173;
    font-size: 18px;
    line-height: 30px;
    margin-top: 5%;
    font-family: 'Poppins';
    letter-spacing: 2px;
}
.effect .effect-img img {
    width: 100%;
    height: 410px;
    transition: all .3s;
    object-fit: cover;
}
.effect .effect-img video {
    width: 100%;
    height: 410px;
    transition: all .3s;
    object-fit: cover;
}
@-webkit-keyframes left-up {
0% {
    left: 65%;
    height: 0;
}
50% {
    left: 0;
    height: 0;
}
100% {
    height: 65%;
    left: 0;
}
}
@-webkit-keyframes right-dn {
0% {
    left: -65%;
    height: 0;
}
50% {
    left: 0;
    height: 0;
}
100% {
    height: 65%;
    left: 0;
}
}

.effect:hover .effect-text:after,
.effect:hover .effect-text:before{
animation-delay: .1s;
animation-duration: .5s;
animation-iteration-count: 1;
animation-timing-function: ease-in-out;
animation-fill-mode: forwards;

}
.effect:hover .effect-text:after{
animation-name: left-up;
}
.effect:hover .effect-text:before{
animation-name: right-dn;
}
.effect .effect-text {
position: absolute;
top: 25px;
right: 25px;
bottom: 25px;
left: 25px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
background: rgba(0, 0, 0, .5);
overflow: hidden;
transition: all .3s ease-in;
opacity: 0;
border: 1px solid #ffffff;
}
.effect .effect-text p{
    color: #ffffff;
    font-size: 17px;
    margin-bottom: 20px;
}
.effect .effect-text h2{
    height: 45px;
    color: #ffffff;
    font-size: 25px;
    margin: 0;
}
.effect .effect-btn .btn{
   
        display: inline-block;
        height: 35px;
        padding: 4px 15px;
        color: rgb(39, 133, 156);
        background: #ffffff;
        font-weight: 600;
        width: 45%;
        font-size: 16px;
        margin-top: 3%;
       
}
.view-button{
    display: flex;
   margin: auto;
    position: relative;
    width: max-content;
    
    margin-bottom: 4%;
    
}
.view-icon{
    width: 40px;
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    background: rgb(39, 133, 156);
    z-index: 1;
    font-size: 1.7rem;
    color: white;
}
a.view-butt{
    text-decoration: none;
}
p.view-paragraph{
font-weight: 600;
text-transform: uppercase;
padding: 8px 20px 0px 25px;
transition: all 0.3s ease-in-out 0s;
color: rgb(0, 0, 0);
z-index: 1;
text-align: center;
letter-spacing: 3px;

}
.view-button::before{
content: "";
position: absolute;
width: 40px;
height: 40px;
left: 0px;
top: 0px;
background: rgb(39, 133, 156);
transition: all 0.3s ease-in-out 0s;
border-radius: 25px;
}
.view-button:hover::before{
    width: 130%;
    cursor: pointer;
    z-index: -1;
}
.view-button:hover  p.view-paragraph{
     color: white;
     padding: 6px 20px 0px 25px;
     cursor: pointer;
     text-decoration: none;
     letter-spacing: 3px;
}

}
@media only screen and (min-width:1501px){
    .portfolio-col{
        text-align: center;
    }
    #portfolio.container-fluid{
        margin-top: 11%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
    }
    .portfolio-title {
        font-family: 'Lato', sans-serif;
        z-index: 1;
        font-size: 60px;
        color: rgba(0, 0, 0, 0.06);
        font-weight: 900;
        letter-spacing: 8px;
        text-align: center;
        font-size: 55px;
        
    }
    .portfolio-small {
        z-index: 2;
        text-align: center;
        margin-top: -45px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
    .portfolio-des{
        text-align: center;
        margin-bottom: 2%;
        font-weight: 600;
        color: #717173;
        font-size: 18px;
        line-height: 30px;
        margin-top: 3%;
        font-family: 'Poppins';
        letter-spacing: 2px;
    }
    .effect .effect-img img {
        width: 100%;
        height: 460px;
        transition: all .3s;
        object-fit: cover;
    }
    .effect .effect-img video {
        width: 100%;
        height: 460px;
        transition: all .3s;
        object-fit: cover;
    }
    @-webkit-keyframes left-up {
    0% {
        left: 65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
    }
    @-webkit-keyframes right-dn {
    0% {
        left: -65%;
        height: 0;
    }
    50% {
        left: 0;
        height: 0;
    }
    100% {
        height: 65%;
        left: 0;
    }
    }
 
    .effect:hover .effect-text:after,
    .effect:hover .effect-text:before{
    animation-delay: .1s;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    
    }
    .effect:hover .effect-text:after{
    animation-name: left-up;
    }
    .effect:hover .effect-text:before{
    animation-name: right-dn;
    }
    .effect .effect-text {
    position: absolute;
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    transition: all .3s ease-in;
    opacity: 0;
    border: 1px solid #ffffff;
    }
    .effect .effect-text p{
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .effect .effect-text h2{
        height: 45px;
        color: #ffffff;
        font-size: 25px;
        margin: 0;
    }
    .effect .effect-btn .btn{
       
            display: inline-block;
            height: 35px;
            padding: 4px 15px;
            color: rgb(39, 133, 156);
            background: #ffffff;
            font-weight: 600;
            width: 35%;
            font-size: 17px;
            margin-top: 10%;
           
    }
    .view-button{
        display: flex;
       margin: auto;
        position: relative;
        width: max-content;
        
        margin-bottom: 4%;
        
    }
    .view-icon{
        width: 40px;
        height: 40px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        background: rgb(39, 133, 156);
        z-index: 1;
        font-size: 1.7rem;
        color: white;
    }
    a.view-butt{
        text-decoration: none;
    }
    p.view-paragraph{
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 20px 0px 25px;
    transition: all 0.3s ease-in-out 0s;
    color: rgb(0, 0, 0);
    z-index: 1;
    text-align: center;
    letter-spacing: 3px;
   
    }
    .view-button::before{
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    background: rgb(39, 133, 156);
    transition: all 0.3s ease-in-out 0s;
    border-radius: 25px;
    }
    .view-button:hover::before{
        width: 130%;
        cursor: pointer;
        z-index: -1;
    }
    .view-button:hover  p.view-paragraph{
         color: white;
         padding: 6px 20px 0px 25px;
         cursor: pointer;
         text-decoration: none;
         letter-spacing: 3px;
    }
    }
    
    a{
        text-decoration: none;
    }



