.portfolio-box {
    background: #fff;
    padding: 20px;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
    margin: 15px 0;
    width: 360px;
    height: 520px;
    position: relative;
}

.projects-image img {
    transition: all .3s;
    width: 320px;
    object-fit: cover;
    height: 370px;
}

.portfolio-box:hover .projects-image img {
    transform: scale(1.1);

}

.portfolio-box:hover .projects-title {
    color: rgb(39, 133, 156);
}

.portfolio-box:hover .projects-image::after {
    bottom: auto;
    top: 0;
    height: 100%;
}

.portfolio-box:hover .projects-icon {
    opacity: 1;
    animation: slideTop .7s ease-in-out 0s 1 normal none running;
    color: rgb(39, 133, 156, );
}

.portfolio-img {
    position: relative;
}

.projects-image {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: zoom-in !important;
}

.projects-image::after {
    content: "";
    position: absolute;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    transition: all .55s ease;
    background: rgb(39, 133, 156, .7);
    pointer-events: none;
}

.projects-icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    transition: all .55s ease;
    opacity: 0;
    width: 50px;
    height: 50px;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    line-height: 55px;
    background: #fff;
    text-align: center;
    color: #dc965a;
    text-decoration: none;
    font-size: 26px;
}

.projects-icon span {

    color: rgb(39, 133, 156);
    cursor: pointer;
}