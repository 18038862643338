@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

@media screen and (max-width: 450px) and (min-width: 320px) {
    .hire-image {
        width: 300px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 10% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
}

@media screen and (max-width: 670px) and (min-width: 451px) {
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 10% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
}

@media screen and (max-width: 870px) and (min-width: 671px) {
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 10% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
}

@media screen and (max-width: 991px) and (min-width: 871px) {
    .hire-image {
        width: 500px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 25%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 10% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
}


@media screen and (max-width: 1140px) and (min-width: 992px) {
    .hire-image {
        width: 485px;
        margin-top: 40%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 20%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin-left: 1%;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1141px) {
    .hire-image {
        width: 500px;
        margin-top: 35%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 20%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin-left: 1%;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1610px) {
    .hire-image {
        width: 600px;
        margin-top: 25%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 20%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin-left: 1%;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1810px) {
    .hire-image {
        width: 600px;
        margin-top: 20%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 18%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: rgb(41, 117, 136);
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin-left: 1%;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
    }
}


.form-wrapper>input {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
}

.form-wrapper>textarea {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: .1px;
    height: 120px;
}

.hire-tel {
    color: rgb(41, 117, 136);
    font-weight: 600;
}

.hire-email {
    color: rgb(41, 117, 136);
    font-weight: 600;
}

.form-wrapper>input:focus {
    border-color: rgb(39, 133, 156);
}

.form-wrapper>textarea:focus {
    border-color: rgb(39, 133, 156);
}



button.hire-button:hover {
    border: 2px solid rgb(43, 98, 112);
    color: black;
    background-color: white;
}
@media screen and (max-width:490px) and (min-width:320px){
    #hire-container {
        margin-top: -50%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width:600px) and (min-width:491px){
    #hire-container {
        margin-top: -35%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width:800px) and (min-width:601px){
    #hire-container {
        margin-top: -20%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width:1000px) and (min-width:801px){
    #hire-container {
        margin-top: -12%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width:1300px) and (min-width:1001px){
    #hire-container {
        margin-top: -10%;
        margin-bottom: 5%;
    }
}
