#footer{
    background-color: black;
    width: 100wh;
    margin-top: 13%;
}
.footer-elements{
    color: white;
    font-size: 18px;
    text-align: center;
}
.social-media-icons span{
    color:white;
    font-size: 160%;
}
.footer-icons-2 span{
    color:white;
    font-size: 160%;
}
.social-media-icons{
    padding-top: 2%;
}
.inner-container{
   padding-top: 6%;
   padding-bottom: 6%;
}
.inner-container a{
    cursor: pointer;
    text-decoration: none;
}
.copyright{
    color:white;
    text-align: center;
    font-size: 15px;
    padding-top: 4%;
}
.footer-icons{
text-align: center;
}
.instagram:hover{
    color: #BC2A8D;
}
.linkedin:hover{
    color: #0077B5;
}
.github:hover{
}