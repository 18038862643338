.blog-head h3{
    font-size: 45px;
    color: #1c1d24;
    font-weight: 500;
    position: relative;
    margin: 0 0 15px;
    padding: 0 0 15px;
    text-align: center;
}
.blog-head h3::after{
    content: "";
    position: absolute;
    width: 40px;
    height: 3px;
    background: #5e6472;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.blog-head p{
text-align: center;
padding-top: 2%;

}
.blog-head{
    margin: 7%;
}
.blog-img{
    position: relative;
}
.blog-data{
    position: absolute;
    top: 20px;
    left: 10px;
    background: #fff;
    padding: 4px 5px;
    line-height: 1;
    text-align: center;
    z-index: 1;
}
.blog-data span{
    width: 100%;
    display: inline-block;
    font-weight: 600;
    font-size: 22px;
    line-height: 1;
    margin: 0;
    color: #1c1d24;
}
.blog-data small{
    font-size: 11px;
    text-transform: uppercase;
    color: #5e6472;
    margin-top: 7px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.blog-text{
    padding: 20px;
    margin: -50px 10px 0;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
    position: relative;
    overflow: hidden;
    background: #fff;
    width: 75%;
    height: 180px;
}
.blog-text-title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.3;
    color: #1c1d24;
    cursor: pointer;
    transition: .3s;
}
.blog-text-title:hover{
  color: rgb(39, 133, 156);
}
.blog-text-button:hover{
  color: rgb(39, 133, 156);
  text-decoration: underline;
}
.blog-text-description{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0 0 12px;
}
.blog-text-button{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .1rem;
    transition: .3s;
    cursor: pointer;
}
.blog-post:hover .blog-img img{
    transform: scale(1.1);
}
.blog-img img{
    transition: all .35s ease;
    cursor: pointer;
    width: 80%;
    height: 300px;
    object-fit: cover;
}