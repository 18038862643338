.services-big {
    font-family: "Lato", sans-serif;
    z-index: 1;
    font-size: 80px;
    color: rgba(0, 0, 0, 0.06);
    font-weight: 900;
    letter-spacing: 8px;
    text-align: center;
  }
  .services-small {
    z-index: 2;
    text-align: center;
    margin-top: -60px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
  }
  #services {
      margin-top: 5%;
      margin-bottom: 5%
  }
  .design {
    background: rgb(39, 133, 156);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 30px;
    color: white;
  }
  .design:hover {
    transform: rotate(360deg);
    transition: 0.4s;
  }
  .services-icon {
    margin-top: 5%;
  }
  .col-services {
    text-align: center;
  }
  .design-des {
    font-size: 22px;
    font-weight: 500;
    padding-top: 5%;
  }
  .services-description {
    line-height: 30px;
    font-weight: 600;
    color: #717173;
    text-align: center;
    margin: auto 7%;
  }
  @media screen and (max-width: 340px) and (min-width: 320px) {
     
      .services-big {
          font-family: "Lato", sans-serif;
          z-index: 1;
          font-size: 40px;
          color: rgba(0, 0, 0, 0.06);
          font-weight: 900;
          letter-spacing: 8px;
          text-align: center;
        }
        .services-small {
          z-index: 2;
          text-align: center;
          margin-top: -37px;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 6px;
        }
        .design {
          background: rgb(39, 133, 156);
          width: 85px;
          height: 85px;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          vertical-align: middle;
          padding: 30px;
          color: white;
        }
        #services {
          margin-top: 10%;
          margin-bottom: 10%
      }
      .services-all{
          margin: 15% auto;
      }
      .services-description {
        line-height: 30px;
        font-weight: 600;
        color: #717173;
        text-align: center;
        margin: 6% 7%;
      }
    }
    
    
    @media screen and (max-width: 415px) and (min-width: 341px) {
      .services-big {
          font-family: "Lato", sans-serif;
          z-index: 1;
          font-size: 40px;
          color: rgba(0, 0, 0, 0.06);
          font-weight: 900;
          letter-spacing: 8px;
          text-align: center;
        }
        .services-small {
          z-index: 2;
          text-align: center;
          margin-top: -37px;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 6px;
        }
        .design {
          background: rgb(39, 133, 156);
          width: 85px;
          height: 85px;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          vertical-align: middle;
          padding: 30px;
          color: white;
        }
        #services {
          margin-top: 10%;
          margin-bottom: 10%
      }
      .services-all{
          margin: 8% auto;
      }
      .services-description {
        line-height: 30px;
        font-weight: 600;
        color: #717173;
        text-align: center;
        margin: 6% 7%;
      }
    
    }
    @media screen and (max-width: 570px) and (min-width: 416px) {
      .services-big {
          font-family: "Lato", sans-serif;
          z-index: 1;
          font-size: 45px;
          color: rgba(0, 0, 0, 0.06);
          font-weight: 900;
          letter-spacing: 8px;
          text-align: center;
        }
        .services-small {
          z-index: 2;
          text-align: center;
          margin-top: -40px;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 6px;
        }
        .design {
          background: rgb(39, 133, 156);
          width: 85px;
          height: 85px;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          vertical-align: middle;
          padding: 30px;
          color: white;
        }
        #services {
          margin-top: 10%;
          margin-bottom: 10%
      }
      .services-all{
          margin: 8% auto;
      }
      .services-description {
        line-height: 30px;
        font-weight: 600;
        color: #717173;
        text-align: center;
        margin: 6% 7%;
      }
    
    }
    
    @media screen and (max-width: 900px) and (min-width: 571px) {
      .services-big {
          font-family: "Lato", sans-serif;
          z-index: 1;
          font-size: 60px;
          color: rgba(0, 0, 0, 0.06);
          font-weight: 900;
          letter-spacing: 8px;
          text-align: center;
        }
        .services-small {
          z-index: 2;
          text-align: center;
          margin-top: -50px;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 22px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 6px;
        }
        .design {
          background: rgb(39, 133, 156);
          width: 95px;
          height: 95px;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          vertical-align: middle;
          padding: 30px;
          color: white;
        }
        #services {
          margin-top: 10%;
          margin-bottom: 10%
      }
      .services-all{
          margin: 8% auto;
     
    }
    .services-description {
      line-height: 30px;
      font-weight: 600;
      color: #717173;
      text-align: center;
      margin: 6% 7%;
    }
  }
    
  