@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');



 @media screen and (max-width: 450px) and (min-width: 320px) {
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: rgb(39, 133, 156);
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #fff;
    transform: translateX(0) rotate(0) scale(0.9);
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 60%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 3%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 35px;
    margin-top: -270px;
  }
  p.how-p {
    text-align: center;
    color: white;
    padding: 4% 3%;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: white;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }

}
@media screen and (max-width: 670px) and (min-width: 451px) {
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: rgb(39, 133, 156);
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #fff;
    transform: translateX(0) rotate(0) scale(0.9);
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 30%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 45px;
    margin-top: -290px;
  }
   p.how-p {
    text-align: center;
    color: white;
    padding: 5% 3%;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: white;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }

}

@media screen and (max-width: 870px) and (min-width: 671px) {
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: rgb(39, 133, 156);
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #fff;
    transform: translateX(0) rotate(0) scale(0.9);
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 23%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 55px;
    margin-top: -310px;
  }
   p.how-p {
    text-align: center;
    color: white;
    padding: 3% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: white;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media screen and (max-width: 1000px) and (min-width: 871px) {
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: rgb(39, 133, 156);
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #fff;
    transform: translateX(0) rotate(0) scale(0.9);
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 20%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 55px;
    margin-top: -330px;
  }
   p.how-p {
    text-align: center;
    color: white;
    padding: 1% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: white;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media screen and (max-width: 1140px) and (min-width: 1001px){
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: #fdfdfd;
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #848484;
    transform: translateX(0) rotate(-11deg) scale(0.95);
    -ms-transform: translateX(0) rotate(-11deg) scale(0.95);
    transition: transform 0.4s ease-out;
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(39, 133, 156);
  transform: translateX(0) rotate(0) scale(0.9);
  background-image: none;
  display: inline-block;
  border: none;
  max-width: 390px;
  margin: 0 15px;
  cursor: pointer;
  font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 12%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 55px;
    margin-top: -420px;
  }
   p.how-p {
    text-align: center;
    color: white;
    padding: 1% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: rgb(178, 206, 134);
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: rgb(178, 206, 134);
  }
  .cards-how-it-work.active:before {
  display: block;
  content: "";
  height: 100%;
  width: 130%;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAAASCAYAAABim40OAAACuElEQVRogc1XUZJjIQjEzN7/yMl8ucP0dkNjXrKh6pWAqERaICveR+vD96vo8cazOvoYX54NwOl6Z52ycc907E4C4a45DfKrwHH5vpPgd7aTYKNOyUvYKL7zRZG6WNRP5cne7pxrMwHLEbCqS3aDMglwpcvjMmTGVz4wejQ86lDfjZ0OeSY/az/VMaJ2XQZA/QQQChSKV99N8OpjvuHvUsGt+ErX2bI9q7Hy0dE580pnZ0R1uTi/eSVX2SLrbmKeAeQG34qIL2KDozo/kwosBv4Ouk5WOheMyrfK5t1Z8K++Ak9XVjK/ZRW8LnMgYDZQtpx5lBFoERxI//z40EFmQLgn2R27PZkP0fDVGmaj5thdoE7JEREPBR6nFJ1+CCAFgq9mVF91dhUcBRgGHMWjju0TZN7xi+nVGmaH8wG2IeaUHH+ibyqRTuxZCVGyAhgrZQi0KrtlYgHYAV1JXvET8IjfF7jnb4mPpMtg2TZbr0rpSnwQO9SzjLAauxV83Zj2j1FU9QtX0fQMfD1VM6zWn9D00agz3fOr866OwRF1mUe9EmaDL8d9HflF5jS/M8Yd1rHzsWzt87HMdr0D+sB6HixPXV/EyqH6QvCqBOE9qPXMTsmMqI3TMG9eNc7P9EJYYvK/KdUsZz0raZF06Hu+DCxBD+BZv1L1RKeAcXsZpnNtgtiEmEOeyRHx0zBvchtnHBWAUFa66q86ggltuj5HZU73pVdAcMHhAEX5xHys/Ge/j43IO/IvfZdxmM4FVDdOs1X1Twr3YWciTYPjZopXZILOxgWElVGIjs5V/Yw752QrxU/A1fGdL4yciz551U45qILa+fbsOmeutekud2I7BeJJhmPr3L07KlN0Y/eMzpmb2JzYntgfXfIr9nLWXgl0RuPLO1xzxdqPOPNK8FxJn+rXhP4HON5K31PHDhmjlm6fAAAAAElFTkSuQmCC);
  background-size: 80% auto;
  background-repeat: no-repeat;
  top: 90%;
  left: -20%;
  right: 0;
  transition: all 0.4s ease-out;
  transform-origin: 30% 50%;
  pointer-events: none;
  z-index: 1;
  transform: translateX(0) rotate(0) scale(0.87);
  font-size: 18px;
} 
.cards-how-it-work.active:hover {
  padding: 18px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(39, 133, 156);
  border: 1px solid rgb(39, 133, 156);
  transform: translateX(0) rotate(0) scale(0.87);
  font-size: 18px;
} 
}
@media only screen and (min-width: 1141px){
.cards-how-it-work.active {
  display: inline-block;
  border: none;
  max-width: 390px;
  margin: 0 15px;
  cursor: pointer;
  background-color: #fdfdfd;
  padding: 18px;
  border-radius: 10px;
  position: relative;
  color: #848484;
  transform: translateX(0) rotate(-11deg) scale(0.95);
  -ms-transform: translateX(0) rotate(-11deg) scale(0.95);
  transition: transform 0.4s ease-out;
  font-size: 18px;
}
.cards-how-it-work {
  padding: 18px;
border-radius: 10px;
color: #fff;
background-color: rgb(39, 133, 156);
transform: translateX(0) rotate(0) scale(0.9);
background-image: none;
display: inline-block;
border: none;
max-width: 390px;
margin: 0 15px;
cursor: pointer;
font-size: 18px;
}
.card-div {
  left: 0;
  display: table;
  width: 100%;
  text-align: center;
  margin-top: 8%;
}
.how-it-work-bg {
  background-image: url("../../assets/bgk.jpeg");
  width: 100%;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(0,0,0, 0.9);
}
h1.how {
  text-align: center;
  padding-top: 10%;
  font-family: 'Dancing Script', cursive;
  color: white;
  font-size: 55px;
  margin-top: -450px;
}
 p.how-p {
  text-align: center;
  color: white;
  padding: 1% 3%;
  font-size: 19px;
  font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
}
.how-it-work-icon.ic{
  width: 60px;
  height: 60px;
  color: rgb(178, 206, 134);
}
.how-it-work-icon {
  width: 60px;
  height: 60px;
  color: rgb(178, 206, 134);
}
.cards-how-it-work.active:before {
display: block;
content: "";
height: 100%;
width: 130%;
position: absolute;
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAAASCAYAAABim40OAAACuElEQVRogc1XUZJjIQjEzN7/yMl8ucP0dkNjXrKh6pWAqERaICveR+vD96vo8cazOvoYX54NwOl6Z52ycc907E4C4a45DfKrwHH5vpPgd7aTYKNOyUvYKL7zRZG6WNRP5cne7pxrMwHLEbCqS3aDMglwpcvjMmTGVz4wejQ86lDfjZ0OeSY/az/VMaJ2XQZA/QQQChSKV99N8OpjvuHvUsGt+ErX2bI9q7Hy0dE580pnZ0R1uTi/eSVX2SLrbmKeAeQG34qIL2KDozo/kwosBv4Ouk5WOheMyrfK5t1Z8K++Ak9XVjK/ZRW8LnMgYDZQtpx5lBFoERxI//z40EFmQLgn2R27PZkP0fDVGmaj5thdoE7JEREPBR6nFJ1+CCAFgq9mVF91dhUcBRgGHMWjju0TZN7xi+nVGmaH8wG2IeaUHH+ibyqRTuxZCVGyAhgrZQi0KrtlYgHYAV1JXvET8IjfF7jnb4mPpMtg2TZbr0rpSnwQO9SzjLAauxV83Zj2j1FU9QtX0fQMfD1VM6zWn9D00agz3fOr866OwRF1mUe9EmaDL8d9HflF5jS/M8Yd1rHzsWzt87HMdr0D+sB6HixPXV/EyqH6QvCqBOE9qPXMTsmMqI3TMG9eNc7P9EJYYvK/KdUsZz0raZF06Hu+DCxBD+BZv1L1RKeAcXsZpnNtgtiEmEOeyRHx0zBvchtnHBWAUFa66q86ggltuj5HZU73pVdAcMHhAEX5xHys/Ge/j43IO/IvfZdxmM4FVDdOs1X1Twr3YWciTYPjZopXZILOxgWElVGIjs5V/Yw752QrxU/A1fGdL4yciz551U45qILa+fbsOmeutekud2I7BeJJhmPr3L07KlN0Y/eMzpmb2JzYntgfXfIr9nLWXgl0RuPLO1xzxdqPOPNK8FxJn+rXhP4HON5K31PHDhmjlm6fAAAAAElFTkSuQmCC);
background-size: 80% auto;
background-repeat: no-repeat;
top: 90%;
left: -20%;
right: 0;
transition: all 0.4s ease-out;
transform-origin: 30% 50%;
pointer-events: none;
z-index: 1;
transform: translateX(0) rotate(0) scale(0.87);
font-size: 18px;
} 
.cards-how-it-work.active:hover {
padding: 18px;
transition: 0.4s;
-webkit-transition: 0.4s;
border-radius: 10px;
color: #fff;
background-color: rgb(39, 133, 156);
border: 1px solid rgb(39, 133, 156);
transform: translateX(0) rotate(0) scale(0.87);
font-size: 18px;
}
}
@media only screen and (min-width: 1610px){
  .cards-how-it-work.active {
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    background-color: #fdfdfd;
    padding: 18px;
    border-radius: 10px;
    position: relative;
    color: #848484;
    transform: translateX(0) rotate(-11deg) scale(0.95);
    -ms-transform: translateX(0) rotate(-11deg) scale(0.95);
    transition: transform 0.4s ease-out;
    font-size: 18px;
  }
  .cards-how-it-work {
    padding: 18px;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(39, 133, 156);
  transform: translateX(0) rotate(0) scale(0.9);
  background-image: none;
  display: inline-block;
  border: none;
  max-width: 390px;
  margin: 0 15px;
  cursor: pointer;
  font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }
  .how-it-work-bg {
    background-image: url("../../assets/bgk.jpeg");
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0,0,0, 0.9);
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: 'Dancing Script', cursive;
    color: white;
    font-size: 55px;
    margin-top: -470px;
  }
   p.how-p {
    text-align: center;
    color: white;
    padding: 1% 3%;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
  }
  .how-it-work-icon.ic{
    width: 60px;
    height: 60px;
    color: rgb(178, 206, 134);
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: rgb(178, 206, 134);
  }
  .cards-how-it-work.active:before {
  display: block;
  content: "";
  height: 100%;
  width: 130%;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAAASCAYAAABim40OAAACuElEQVRogc1XUZJjIQjEzN7/yMl8ucP0dkNjXrKh6pWAqERaICveR+vD96vo8cazOvoYX54NwOl6Z52ycc907E4C4a45DfKrwHH5vpPgd7aTYKNOyUvYKL7zRZG6WNRP5cne7pxrMwHLEbCqS3aDMglwpcvjMmTGVz4wejQ86lDfjZ0OeSY/az/VMaJ2XQZA/QQQChSKV99N8OpjvuHvUsGt+ErX2bI9q7Hy0dE580pnZ0R1uTi/eSVX2SLrbmKeAeQG34qIL2KDozo/kwosBv4Ouk5WOheMyrfK5t1Z8K++Ak9XVjK/ZRW8LnMgYDZQtpx5lBFoERxI//z40EFmQLgn2R27PZkP0fDVGmaj5thdoE7JEREPBR6nFJ1+CCAFgq9mVF91dhUcBRgGHMWjju0TZN7xi+nVGmaH8wG2IeaUHH+ibyqRTuxZCVGyAhgrZQi0KrtlYgHYAV1JXvET8IjfF7jnb4mPpMtg2TZbr0rpSnwQO9SzjLAauxV83Zj2j1FU9QtX0fQMfD1VM6zWn9D00agz3fOr866OwRF1mUe9EmaDL8d9HflF5jS/M8Yd1rHzsWzt87HMdr0D+sB6HixPXV/EyqH6QvCqBOE9qPXMTsmMqI3TMG9eNc7P9EJYYvK/KdUsZz0raZF06Hu+DCxBD+BZv1L1RKeAcXsZpnNtgtiEmEOeyRHx0zBvchtnHBWAUFa66q86ggltuj5HZU73pVdAcMHhAEX5xHys/Ge/j43IO/IvfZdxmM4FVDdOs1X1Twr3YWciTYPjZopXZILOxgWElVGIjs5V/Yw752QrxU/A1fGdL4yciz551U45qILa+fbsOmeutekud2I7BeJJhmPr3L07KlN0Y/eMzpmb2JzYntgfXfIr9nLWXgl0RuPLO1xzxdqPOPNK8FxJn+rXhP4HON5K31PHDhmjlm6fAAAAAElFTkSuQmCC);
  background-size: 80% auto;
  background-repeat: no-repeat;
  top: 90%;
  left: -20%;
  right: 0;
  transition: all 0.4s ease-out;
  transform-origin: 30% 50%;
  pointer-events: none;
  z-index: 1;
  transform: translateX(0) rotate(0) scale(0.87);
  font-size: 18px;
  } 
  .cards-how-it-work.active:hover {
  padding: 18px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(39, 133, 156);
  border: 1px solid rgb(39, 133, 156);
  transform: translateX(0) rotate(0) scale(0.87);
  font-size: 18px;
  }
  }
  @media only screen and (min-width: 1810px){
    .cards-how-it-work.active {
      display: inline-block;
      border: none;
      max-width: 390px;
      margin: 0 15px;
      cursor: pointer;
      background-color: #fdfdfd;
      padding: 18px;
      border-radius: 10px;
      position: relative;
      color: #848484;
      transform: translateX(0) rotate(-11deg) scale(0.95);
      -ms-transform: translateX(0) rotate(-11deg) scale(0.95);
      transition: transform 0.4s ease-out;
      font-size: 18px;
    }
    .cards-how-it-work {
      padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
    }
    .card-div {
      left: 0;
      display: table;
      width: 100%;
      text-align: center;
      margin-top: 5%;
    }
    .how-it-work-bg {
      background-image: url("../../assets/bgk.jpeg");
      width: 100%;
      height: 380px;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-color: rgba(0,0,0, 0.9);
    }
    h1.how {
      text-align: center;
      padding-top: 10%;
      font-family: 'Dancing Script', cursive;
      color: white;
      font-size: 55px;
      margin-top: -520px;
    }
     p.how-p {
      text-align: center;
      color: white;
      padding: 1% 3%;
      font-size: 19px;
      font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1px;
    }
    .how-it-work-icon.ic{
      width: 60px;
      height: 60px;
      color: rgb(178, 206, 134);
    }
    .how-it-work-icon {
      width: 60px;
      height: 60px;
      color: rgb(178, 206, 134);
    }
    .cards-how-it-work.active:before {
    display: block;
    content: "";
    height: 100%;
    width: 130%;
    position: absolute;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAAASCAYAAABim40OAAACuElEQVRogc1XUZJjIQjEzN7/yMl8ucP0dkNjXrKh6pWAqERaICveR+vD96vo8cazOvoYX54NwOl6Z52ycc907E4C4a45DfKrwHH5vpPgd7aTYKNOyUvYKL7zRZG6WNRP5cne7pxrMwHLEbCqS3aDMglwpcvjMmTGVz4wejQ86lDfjZ0OeSY/az/VMaJ2XQZA/QQQChSKV99N8OpjvuHvUsGt+ErX2bI9q7Hy0dE580pnZ0R1uTi/eSVX2SLrbmKeAeQG34qIL2KDozo/kwosBv4Ouk5WOheMyrfK5t1Z8K++Ak9XVjK/ZRW8LnMgYDZQtpx5lBFoERxI//z40EFmQLgn2R27PZkP0fDVGmaj5thdoE7JEREPBR6nFJ1+CCAFgq9mVF91dhUcBRgGHMWjju0TZN7xi+nVGmaH8wG2IeaUHH+ibyqRTuxZCVGyAhgrZQi0KrtlYgHYAV1JXvET8IjfF7jnb4mPpMtg2TZbr0rpSnwQO9SzjLAauxV83Zj2j1FU9QtX0fQMfD1VM6zWn9D00agz3fOr866OwRF1mUe9EmaDL8d9HflF5jS/M8Yd1rHzsWzt87HMdr0D+sB6HixPXV/EyqH6QvCqBOE9qPXMTsmMqI3TMG9eNc7P9EJYYvK/KdUsZz0raZF06Hu+DCxBD+BZv1L1RKeAcXsZpnNtgtiEmEOeyRHx0zBvchtnHBWAUFa66q86ggltuj5HZU73pVdAcMHhAEX5xHys/Ge/j43IO/IvfZdxmM4FVDdOs1X1Twr3YWciTYPjZopXZILOxgWElVGIjs5V/Yw752QrxU/A1fGdL4yciz551U45qILa+fbsOmeutekud2I7BeJJhmPr3L07KlN0Y/eMzpmb2JzYntgfXfIr9nLWXgl0RuPLO1xzxdqPOPNK8FxJn+rXhP4HON5K31PHDhmjlm6fAAAAAElFTkSuQmCC);
    background-size: 80% auto;
    background-repeat: no-repeat;
    top: 90%;
    left: -20%;
    right: 0;
    transition: all 0.4s ease-out;
    transform-origin: 30% 50%;
    pointer-events: none;
    z-index: 1;
    transform: translateX(0) rotate(0) scale(0.87);
    } 
    .cards-how-it-work.active:hover {
    padding: 18px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(39, 133, 156);
    border: 1px solid rgb(39, 133, 156);
    transform: translateX(0) rotate(0) scale(0.87);
    }
    }

