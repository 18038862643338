@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


@media screen and (max-width: 394px) and (min-width: 320px) {
  #testimonials{
    height: 120vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 2% 15%;
  text-align: center;
  font-size: 16px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 81%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 1%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 80%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 15%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
/* [dir="rtl"] .slick-slide {
  float: right;
}
[dir="ltr"] .slick-slide {
  float: left;
} */
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 40%;

}
}
@media screen and (max-width: 490px) and (min-width: 395px) {
  #testimonials{
    height: 120vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 4% 15%;
  text-align: center;
  font-size: 17px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 81%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 2%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 80%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 12%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility:visible;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 40%;

}
}
@media screen and (max-width: 590px) and (min-width: 491px) {
  #testimonials{
    height: 110vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 5% 15%;
  text-align: center;
  font-size: 17px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 72%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 2%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 72%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 12%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 30%;

}
}
@media screen and (max-width: 700px) and (min-width: 591px) {
  #testimonials{
    height: 115vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 7% 15%;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 72%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 6%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 72%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 14%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 30%;

}
}
@media screen and (max-width: 850px) and (min-width: 701px) {
  #testimonials{
    height: 110vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 7% 15%;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 60%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 6%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 60%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 14%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 20%;

}
}
@media screen and (max-width: 1000px) and (min-width: 851px) {
  div.testimonials-div{
    margin-bottom: 20%;
  
  }
  #testimonials{
    height: 100vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 4% 15%;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 55%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 6%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 55%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 14%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 10%;

}
}
@media screen and (max-width: 1300px) and (min-width: 1001px) {
  #testimonials{
    height: 100vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 5% 17%;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 8%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 14%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 6%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 14%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 14%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
div.testimonials-div{
  margin-bottom: 10%;

}
}
@media only screen and (min-width: 1301px){
  div.testimonials-div{
    margin-bottom: 10%;
  
  }
  #testimonials{
    height: 90vh;
    background-image: url("../../assets/wave2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.testimonials-txt{
  margin: 3% 25%;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 2;
}
.testimonials-title{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 4px;
  padding-top: 5%;
}
button.slick-arrow.slick-prev{
  font-size: 0;
  line-height: 0;
  top: 14%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  left: 6%;
}


button.slick-arrow.slick-next{
  font-size: 0;
  line-height: 0;
  top: 14%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  position: absolute;
  right: 14%;
} 
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow {
  display: block;
}

/* .slick-prev:before{
  content: '←';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
}
.slick-next:before{
  content: '→';
  font-size: 30px;
  line-height: 1;
  top: 50%;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  border: 2px solid rgb(41, 117, 136);
  padding-top: 50%;
  
} */
}



.slider{
    width: 100%;
    overflow: hidden;
}


.testimonials-name{
    text-align: center;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;

}
.testimonials-role{
    text-align: center;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;

}





 
  
