@media screen and (max-width: 399px) and (min-width: 300px) {
  .bg {
    width: 100%;
    height: 50vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 25%;
    padding-left: 10%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 0.5;
  }
  .fjalia-1 {
    color: black;
    font-size: 22px;
    letter-spacing: normal;
    font-weight: 400;
  }

  .fjalia-2 {
    color: black;
    font-size: 14px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .read-button {
    width: 40%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    margin-top: 8%;
    line-height: 1.5;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 7px;
    margin: 0;
    font-size: 11px;
  }
  span.Typewriter__cursor{
    font-size: 15px;
  }
}

@media screen and (max-width: 430px) and (min-width: 400px) {
  .bg {
    width: 100%;
    height: 50vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 20%;
    padding-left: 10%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 0.8;
  }
  .fjalia-1 {
    color: black;
    font-size: 26px;
    letter-spacing: normal;
    font-weight: 400;
  }

  .fjalia-2 {
    color: black;
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .read-button {
    width: 40%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    margin-top: 8%;
    line-height: 1.5;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 7px;
    margin: 0;
    font-size: 11px;
  }
  span.Typewriter__cursor{
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) and (min-width: 431px) {
  .bg {
    width: 100%;
    height: 50vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 18%;
    padding-left: 10%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 2.3;
  }
  .fjalia-1 {
    color: black;
    font-size: 26px;
    letter-spacing: normal;
    font-weight: 400;
  }

  .fjalia-2 {
    color: black;
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .read-button {
    width: 40%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    margin-top: 8%;
    line-height: 1.5;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 7px;
    margin: 0;
    font-size: 11px;
  }
  span.Typewriter__cursor{
    font-size: 15px;
  }
}
@media screen and (max-width: 620px) and (min-width: 501px) {
  .bg {
    width: 100%;
    height: 50vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 18%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 3.5;
  }

  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 32px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 21px;
  }
  .read-button {
    width: 40%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 50px;
    margin-top: 3%;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    font-size: 12px;
  }
  span.Typewriter__cursor{
    font-size: 18px;
  }
}
@media screen and (max-width: 860px) and (min-width: 620px) {
  .bg {
    width: 100%;
    height: 50vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 14%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 3.5;
  }

  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 32px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 21px;
  }
  .read-button {
    width: 30%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 50px;
    margin-top: 3%;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    font-size: 12px;
  }
  span.Typewriter__cursor{
    font-size: 18px;
  }
}
@media screen and (max-width: 949px) and (min-width: 861px) {
  .bg {
    width: 100%;
    height: 60vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 14%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 3.5;
  }

  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 37px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 23px;
  }
  .read-button {
    width: 25%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 50px;
    margin-top: 3%;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
  .read-button:hover {
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }
  span.Typewriter__cursor{
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 950px) {
  .bg {
    width: 100%;
    height: 70vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 14%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
  }
  .read-button {
    width: 20%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 50px;
    margin-top: 3%;
  }
  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 38px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 21px;
  }
  .read-button {
    width: 25%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 50px;
    margin-top: 3%;
  }
  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 7px;
    margin: 0;
    font-size: 13px;
  }
  .read-button:hover {
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }
  span.Typewriter__cursor{
    font-size: 19px;
  }
}
@media screen and (max-width:1600px) and (min-width: 1201px) {
  .bg {
    width: 100%;
    height: 80vh;
    text-align: center;
    background-image: url(../../assets/bg3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
  }
  .heroTextSection {
    text-align: left;
    padding-top: 12%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
    line-height: 3.8;
  }
  .read-button {
    width: 20%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 54px;
    margin-top: 2%;
  }
  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 65px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 35px;
  }

  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
  .read-button:hover {
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }
  span.Typewriter__cursor{
    font-size: 19px;
  }
}

@media screen  and (min-width:1601px){
  

.bg {
  width: 100%;
  height: 85vh;
  text-align: center;
  background-image: url(../../assets/bg3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  margin-top: 0%;
  overflow: hidden;
}

  .heroTextSection {
    text-align: left;
    padding-top: 10%;
    padding-left: 12%;
    font-weight: 4vw;
    font-family: "Roboto", sans-serif;
  } 
  .read-button {
    width: 13%;
    height: 10%;
    border-radius: 7px;
    margin-right: 2%;
    border: 0;
    background-color: rgb(39, 133, 156);
    height: 54px;
    margin-top: 2%;
  }
  .fjalia-1 {
    color: black;
    font-size: 4vw;
    line-height: 5.8vw;
    font-size: 65px;
  }

  .fjalia-2 {
    color: black;
    font-size: 2.6vw;
    line-height: 5.8vw;
    font-size: 35px;
  }

  .read-button p {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    font-size: 16px;
  }
  
  .buton-section {
    display: flex;
    margin-left: 5%;
  }
  
  .read-button:hover {
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }
  span.Typewriter__cursor{
    font-size: 23px;
  }
}
  

  